<template>
  <div class="py-6 w-full px-3">
    <card class="w-full p-5">
      <h1 class="text-xl text-left font-bold flex mb-0 -mt-2">
        Change Security Settings
      </h1>
      <div class="flex flex-col gap-9">
        <div class="flex justify-between items-center mt-6">
          <div class="flex flex-col">
            <p class="text-darkPurple text-l font-bold">My Password</p>
            <div class="flex mt-1">
              <span class="text-sm"
                >The same password strength are enforced for all users across
                the app.</span
              >
              <span>
                <Menu right top="-200" margin="90" class="p-0 -mt-1">
                  <template v-slot:title>
                    <span class="text-blueCrayola text-sm ml-2 mt-1">
                      View Policy
                    </span>
                  </template>
                  <div style="width: 300px; padding: 10px">
                    <div class="flex">
                      <div class="w-full mt-4 text-lg font-bold">
                        Password Strength
                      </div>
                    </div>
                    <div class="w-full my-3 text-sm">
                      All passwords must satisfy these requirements. Passwords
                      Must have:
                    </div>
                    <div class="border w-full" />
                    <div class="w-full">
                      <ul>
                        <li>Must have at least 1 upper case</li>
                        <li>Must have at least 1 lower case</li>
                        <li>Must have at least 1 number</li>
                        <li>
                          Must have at least 1 special character or symbol
                        </li>
                        <li>Must be at least 8 characters</li>
                      </ul>
                    </div>
                  </div>
                </Menu>
              </span>
            </div>
          </div>
          <Button
            background-color="none"
            class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4 rounded mt-2"
            v-if="!hasSubscriptionExpired()"
            @click="passwordModal = true"
          >
            Update Password
          </Button>
        </div>
      </div>
    </card>
    <ChangePassword
      :open-modal="passwordModal"
      @close="passwordModal = false"
    />
  </div>
</template>

<script>
import Button from "@/components/Button";
import Card from "@/components/Card";
import Menu from "@/components/Menu";
import ChangePassword from "./sideActions/ChangePassword";

export default {
  components: {
    Button,
    Card,
    ChangePassword,
    Menu
  },

  data() {
    return {
      passwordModal: false
    };
  },
  computed: {
    hasSubscriptionExpired() {
      return this.$store.getters.hasSubscriptionExpired;
    }
  }
};
</script>

<style>
ul {
  padding-left: 20px;
}
li {
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0px;
}
</style>
