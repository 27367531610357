<template>
  <div>
    <div class=" w-full">
      <p class=" font-semibold mb-4 inline-block">
        Work Hours
      </p>
    </div>
    <div
      class="grid grid-cols-12 gap-6 text-darkPurple"
      style="margin-top:1.125rem"
    >
      <div class="col-span-4">
        <div class="text-sm mb-1 text-jet">
          Work Hours
        </div>
        <div class="flex">
          <div class="disabled w-36">
            {{ hoursPerDay }}
          </div>
          <p class="mt-2 ml-2 ">
            Hours Per Day
          </p>
        </div>
      </div>
      <div class="col-span-4 ">
        <div class="text-sm mb-1 text-jet">
          Work Days
        </div>
        <div class="flex">
          <div class="disabled w-36">
            {{ daysPerWeek }}
          </div>
          <p class="mt-2 ml-2 ">
            Days Per Week
          </p>
        </div>
      </div>
      <div class="col-span-4">
        <div class="text-sm mb-1 text-jet">
          Work Weeks
        </div>
        <div class="flex">
          <div class="disabled w-36">
            {{ weeksPerAnnum }}
          </div>
          <p class="mt-2 ml-2 ">
            Weeks Per Year
          </p>
        </div>
      </div>
      <div class="col-span-3">
        <div class="text-sm mb-2 text-jet">
          Total Work Hours
        </div>
        <div class="payInfo payInfo_disabled text-center">
          {{ totalWorkHours }}
        </div>
      </div>
      <div class="col-span-3">
        <div class="text-sm mb-2 text-jet">
          Hourly Work Rate(₦)
        </div>
        <div class="payInfo payInfo_disabled text-center">
          {{ hourlyWorkRate }}
        </div>
      </div>
      <div class="col-span-3">
        <div class="text-sm mb-2 text-jet">
          Daily Work Rate(₦)
        </div>
        <div class="payInfo payInfo_disabled text-center">
          {{ dailyWorkRate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      daysPerWeek: null,
      weeksPerAnnum: "52",
      hoursPerDay: null,
      totalWorkHours: "0",
      hourlyWorkRate: "0",
      dailyWorkRate: "0"
    };
  },
  computed: {
    ...mapState({
      employeeDetails: state => state.employee.allEmployeeDetails
    })
  },
  watch: {
    daysPerWeek() {
      return this.checkDateCalculation();
    },
    hoursPerDay() {
      this.checkDateCalculation();
    },
    weeksPerAnnum() {
      return this.checkDateCalculation();
    }
  },
  methods: {
    checkDateCalculation() {
      const amount = parseFloat(this.employeeDetails.grossPay);
      if (this.daysPerWeek && this.hoursPerDay && this.weeksPerAnnum) {
        const hourPerDay = parseFloat(this.hoursPerDay);
        const daysPerWeek = parseFloat(this.daysPerWeek);
        const weeksPerAnnum = parseFloat(this.weeksPerAnnum);
        const hourRate = amount / (hourPerDay * daysPerWeek * weeksPerAnnum);
        const dailyRate = amount / (daysPerWeek * weeksPerAnnum);
        this.hourlyWorkRate = hourRate.toLocaleString();
        this.dailyWorkRate = dailyRate.toLocaleString();
        this.totalWorkHours = Number(hourPerDay * daysPerWeek * weeksPerAnnum);
      }
    }
  },
  async mounted() {
    const { daysPerWeek, weeksPerAnnum, hoursPerDay } = await this
      .employeeDetails;
    this.daysPerWeek = daysPerWeek.toString();
    this.weeksPerMonth = weeksPerAnnum.toString();
    this.hoursPerDay = hoursPerDay.toString();
    this.checkDateCalculation();
  }
};
</script>

<style lang="scss">
.payInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  &_fakeInput {
    background: #f7f7ff;
    border-color: #878e99;
    padding: 10px 15px;
    max-height: 40px;
  }
  &_disabled {
    padding: 10px 15px;
    min-height: 40px;
    background: #eeeeee;
    border-color: #878e99;
  }
  &_unallocated {
    padding: 12px 16px;
    background: #fff5f5;
    border-color: #ea3c53;
    max-height: 65px;
  }
}
</style>
