var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-10/12"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"w-full"},[_c('Tab',{attrs:{"tabs":[
          'Annual Gross Pay',
          'Other Benefits',
          'Work Hours',
          'Account Information'
        ],"border":"","active-tab":_vm.currentTab},on:{"currentTab":function($event){_vm.currentTab = $event}}})],1),(_vm.loading)?_c('div',{staticStyle:{"height":"100%"}},[_c('loader',{attrs:{"size":"xxs","loader-image":false}})],1):_c('div',{staticClass:"mt-7 flex-grow"},[(_vm.currentTab === 'Annual Gross Pay')?_c('annual-gross',{attrs:{"gross-earnings-data":_vm.grossEarningsData,"total-gross-earning":_vm.totalGrossEarning},on:{"grossPay":function($event){return _vm.getPaysettings()}}}):_vm._e(),(_vm.currentTab === 'Other Benefits')?_c('other-benefits',{attrs:{"other-benefit-data":_vm.otherBenefitData},on:{"submit":function($event){return _vm.getPaysettings()}}}):_vm._e(),(_vm.currentTab === 'Work Hours')?_c('work-hours'):_vm._e(),(_vm.currentTab === 'Account Information')?_c('account-info'):_vm._e(),(_vm.currentTab === 'error')?_c('div',[_c('h1',{staticClass:"font-bold text-xl text-center"},[_vm._v(" Admin has not updated your Pay information ")])]):_vm._e()],1),_c('divider',{staticClass:"my-8"}),_vm._m(0)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex mb-5"},[_c('div',{staticClass:"flex mt-4 flex-grow"})])
}]

export { render, staticRenderFns }