<template>
  <div class="w-10/12">
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class=" mt-6">
      <div class=" w-full">
        <p class=" font-semibold mb-7 inline-block">
          Employment Details
        </p>
      </div>
      <div
        class="grid grid-cols-12 gap-6 text-darkPurple"
        style="margin-top:1.125rem"
      >
        <div class="col-span-3">
          <div class="text-sm mb-1 text-jet">
            Employee ID
          </div>
          <div class="disabled">
            {{ employment.employeeId }}
          </div>
        </div>

        <div class="col-span-3">
          <div class="text-sm mb-1 text-jet">
            Engagement Date
          </div>
          <div class="disabled">
            {{  employment.resumptionDate }}
          </div>
        </div>
        <div class="col-span-3">
          <div class="text-sm mb-1 text-jet">
            Employment Type
          </div>
          <div class="disabled">
            {{ employment.employmentType }}
          </div>
        </div>
        <div class="col-span-3">
          <div class="text-sm mb-1 text-jet">
            Primary Status
          </div>
          <div class="disabled">
            {{ employment.isActive ? "Active" : "In Active" }}
          </div>
        </div>
        <div class="col-span-3">
          <div class="text-sm mb-1 text-jet">
            Secondary Status
          </div>
          <div class="disabled">
            {{ employment.employmentStatus }}
          </div>
        </div>
        <div class="col-span-3">
          <div class="text-sm mb-1 text-jet">
            Function
          </div>
          <div class="disabled">
            {{ employment.orgFunction ? employment.orgFunction.name : "N/A" }}
          </div>
        </div>

        <div class="col-span-3">
          <div class="text-sm mb-1 text-jet">
            Designation
          </div>
          <div class="disabled">
            {{
              employment.userDesignation
                ? employment.userDesignation.name
                : "N/A"
            }}
          </div>
        </div>
        <div class="col-span-3">
          <div class="text-sm mb-1 text-jet">
            Job Level
          </div>
          <div class="disabled">
            {{ employment.userLevel ? employment.userLevel.name : "N/A" }}
          </div>
        </div>
        <div class="col-span-3">
          <div class="text-sm mb-1 text-jet">
            Reporting To
          </div>
          <div class="disabled">
            {{
              employment.userReportingTo
                ? `${employment.userReportingTo.fname} ${employment.userReportingTo.lname}`
                : "Nil"
            }}
          </div>
        </div>
        <div class="col-span-3">
          <div class="text-sm mb-1 text-jet">
            Effective Payroll Date
          </div>
          <div class="disabled">
            {{ employmentDate }}
          </div>
        </div>
        <div class="col-span-3">
          <div class="text-sm mb-1 text-jet">
            Office Location
          </div>
          <div class="disabled">
            {{
              employment.employeeOffice ? employment.employeeOffice.name : "N/A"
            }}
          </div>
        </div>
      </div>
      <divider class="my-4" />
    </div>
  </div>
</template>

<script>
import Divider from "@/components/divider";

export default {
  name: "EmploymentDelails",
  components: {
    Divider
  },

  data() {
    return {
      employmentDate: "",
      allOrgUsers: [],
      reportingTo: "",
      loading: true,
      employment: {
        employeeId: "",
        employmentStatus: "",
        employmentType: "",
        isActive: null,
        orgFunction: {
          name: ""
        },
        userLevel: { name: "" },
        userDesignation: { name: "" },
        reportingTo: "",
        resumptionDate: "",
        employeeOffice: { name: "" }
      }
    };
  },
  computed: {
    setFunctions() {
      return this.$store.state.org.getAllFunctions;
    },

    setDesignation() {
      return this.$store.state.org.getAllFunctions.find(
        item => item.id === this.employment.functionId
      );
    },
    setLevel() {
      let result;
      if (this.setDesignation) {
        result = this.setDesignation.designations.find(
          item => item.id === this.employment.designation
        );
      }

      return result ? result.level : "";
    }
  },
  methods: {
    async setAllOrgUsers(reportTo) {
      try {
        const { data } = await this.$_getAllOrgUsers("");
        const findDataIndex = data.findIndex(item => item.id === reportTo);
        this.reportingTo =
          findDataIndex >= 0 ? data[findDataIndex].name : "Nil";
      } catch (err) {
        throw new Error(err);
      }
    },
    getEmploymentInfo() {
      this.$_getOneEmployeeAll({
        id: this.$AuthUser.id
      })
        .then(({ data }) => {
          this.employment = data;
          this.setAllOrgUsers(data.reportingTo);
          this.employmentDate = this.employment.employmentDate;
          this.loading = false;
        })
        .catch(err => {
          throw new Error(err);
        });
    }
  },
  mounted() {
    this.$_getHumanarLocations().then(result => {
      this.getEmploymentInfo();
      this.locationData = result.data.outlets;
    });
  }
};
</script>

<style></style>
