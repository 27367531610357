<template>
  <RightSideBar
    v-if="openModal"
    @submit="savePassword"
    @close="closeModal()"
    :loading="loading"
    submit="Save"
    :button-class="`bg-dynamicBackBtn text-white`"
    :disabled-button="disableBtn"
  >
    <template v-slot:title>
      <p class="text-darkPurple">Update Password</p>
    </template>
    <div>
      <div
        :class="[
          'flex flex-col items-start gap-3',
          visibility.first ? '' : 'opacity-50'
        ]"
      >
        <p class="text-darkPurple text-base">
          You are about to change your password.
        </p>
        <p class="text-darkPurple text-sm">
          Start by entering your old password below
        </p>
        <span class="text-red-500 mr-4" v-if="error.first"
          >Incorrect Password</span
        >
        <CText
          placeholder="--Enter--"
          :variant="error.first ? 'error' : ''"
          label="Current Password"
          :disabled="!visibility.first"
          class="w-full mt-2"
          v-model="password"
          :type="firstPassword"
        />
        <checkbox
          label="Show Password"
          checkbox-size="width:16px; height: 16px"
          class="flex justify-center items-center"
          v-model="decryptFirstPassword"
        />
        <Button
          @click="checkPassword"
          :disabled="!visibility.first"
          :class="[' flex', visibility.first ? 'text-flame' : 'text-jet']"
        >
          <span class="mr-24"> Continue </span>
        </Button>
      </div>
      <divider class="my-4" border-dash />
      <div
        :class="[
          'flex flex-col items-start gap-3',
          visibility.second ? '' : 'opacity-50'
        ]"
      >
        <CText
          placeholder="--Enter--"
          variant="w-full"
          label="New Password"
          :disabled="!visibility.second"
          class="w-full mt-2"
          v-model="newPassword"
          @input="scorePassword"
          :type="secondPassword"
        />
        <checkbox
          label="Show Password"
          checkbox-size="width:16px; height: 16px"
          class="flex justify-center items-center"
          v-model="decryptSecondPassword"
        />
        <div class="flex w-full">
          <div
            class="mr-2"
            style="width: 12.5%"
            v-for="(number, index) in passwordLength"
            :key="index"
          >
            <transition name="slide-fade">
              <div
                :class="`h-1 mr-2 rounded-xl ${bgColor}`"
                :style="`width: 100%;`"
              />
            </transition>
          </div>
        </div>
        <div class="w-full flex flex-col mt-1 mb-2">
          <p
            class="font-semibold uppercase mb-1 mt-1 text-romanSilver"
            style="font-size: 10px"
          >
            All passwords must satisfy these requirements. Passwords Must have:
          </p>
          <div class="w-full flex">
            <ul>
              <li :class="has_uppercase ? 'text-mediumSeaGreen' : ''">
                Must have at least 1 upper case
              </li>
              <li :class="has_lowercase ? 'text-mediumSeaGreen' : ''">
                Must have at least 1 lower case
              </li>
              <li :class="has_number ? 'text-mediumSeaGreen' : ''">
                Must have at least 1 number
              </li>
              <li :class="has_special ? 'text-mediumSeaGreen' : ''">
                Must have at least 1 special character or symbol
              </li>
              <li :class="has_complete ? 'text-mediumSeaGreen' : ''">
                Must be at least 8 characters
              </li>
            </ul>
          </div>
        </div>
        <c-text
          placeholder="--Enter--"
          :variant="error.second ? 'error' : ''"
          label="Confirm New Password"
          class="my-4 w-full"
          :disabled="!visibility.second"
          v-model="confirmPassword"
          :type="thirdPassword"
        />
        <span class="text-red-500" v-if="error.second"
          >Passwords do not match!
        </span>
        <checkbox
          label="Show Password"
          checkbox-size="width:16px; height: 16px"
          class="flex justify-center items-center"
          v-model="decryptThirdPassword"
        />
      </div>
    </div>
  </RightSideBar>
</template>

<script>
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Button from "@/components/Button";
import RightSideBar from "@/components/RightSideBar";
import Checkbox from "@/components/Checkbox";

export default {
  components: {
    CText,
    Button,
    RightSideBar,
    Checkbox
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      disableBtn: true,
      confirmPassword: "",
      newPassword: "",
      newPasswordLength: "",
      password: "",
      decryptFirstPassword: false,
      decryptSecondPassword: false,
      decryptThirdPassword: false,
      firstPassword: "password",
      secondPassword: "password",
      thirdPassword: "password",
      bgColor: "bg-carrotOrange",
      width: "0%",
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      has_complete: false,
      loading: false,
      passwordModal: false,
      factorModal: false,
      passwordLength: [],
      bgButton: "bg-fadeFlame",
      savePasswd: false,
      response: "",
      responseBg: "",
      success: false,
      visibility: {
        first: true,
        second: false,
        third: false
      },
      error: {
        first: false,
        second: false,
        third: false
      }
    };
  },
  watch: {
    confirmPassword() {
      if (this.newPassword !== "") {
        if (this.newPassword !== this.confirmPassword) {
          this.error.second = true;
          this.disableBtn = true;
        } else {
          this.error.second = false;
          this.disableBtn = false;
        }
      }
    },
    decryptFirstPassword() {
      if (this.decryptFirstPassword) {
        this.firstPassword = "";
      } else {
        this.firstPassword = "password";
      }
    },
    decryptSecondPassword() {
      if (this.decryptSecondPassword) {
        this.secondPassword = "";
      } else {
        this.secondPassword = "password";
      }
    },
    decryptThirdPassword() {
      if (this.decryptThirdPassword) {
        this.thirdPassword = "";
      } else {
        this.thirdPassword = "password";
      }
    }
  },
  methods: {
    setButton() {
      this.bgButton = "bg-flame";
      this.savePasswd = true;
    },

    resetButton() {
      this.bgButton = "bg-fadeFlame";
      this.savePasswd = false;
    },

    scorePassword() {
      this.has_number = /\d/.test(this.newPassword);
      this.has_lowercase = /[a-z]/.test(this.newPassword);
      this.has_uppercase = /[A-Z]/.test(this.newPassword);
      this.has_special = /\W/.test(this.newPassword);
      if (this.newPassword.length === 8) {
        this.has_complete = true;
      }

      this.passwordLength = [1, 2, 3, 4, 5, 6, 7, 8];

      if (this.newPassword.length === 0) {
        this.passwordLength = [];
        this.has_number = false;
        this.has_lowercase = false;
        this.has_uppercase = false;
        this.has_special = false;
        this.has_complete = false;
      }

      for (let i = 0; i < 9; i++) {
        if (
          this.has_number &&
          this.has_lowercase &&
          this.has_uppercase &&
          this.has_special &&
          this.has_complete
        ) {
          this.bgColor = "bg-mediumSeaGreen";
        } else {
          this.bgColor = "bg-carrotOrange";
        }
      }
    },
    checkPassword() {
      this.error.first = false;
      this.loading = true;
      this.$_checkPassword({
        userId: this.$AuthUser.id,
        password: this.password
      })
        .then(() => {
          this.loading = false;
          this.visibility.first = false;
          this.visibility.second = true;
        })
        .catch(() => {
          this.loading = false;
          this.error.first = true;
        });
    },
    savePassword() {
      this.disableBtn = true;
        if (
          this.has_number &&
          this.has_lowercase &&
          this.has_uppercase &&
          this.has_special &&
          this.has_complete
        ) {
          const requestpayload = {
            userId: this.$AuthUser.id,
            previousPassword: this.password,
            newPassword: this.newPassword
          };
          this.$_updatePassword(requestpayload)
            .then((result) => {
              this.disableBtn = false;
              this.success = result.data.success;
              if (this.success) {
                this.$toasted.success(result.data.msg, { duration: 5000 });
                this.closeModal();
              } else {
                this.$toasted.error(result.data.msg, { duration: 5000 });
              }
            })
            .catch((err) => {
              throw new Error(err);
            });
        } else {
          this.$toasted.error("New Password does not meet the requirements", { duration: 5000 });
          this.disableBtn = false;
          this.success = false;
      }
    },

    closeModal() {
      this.$emit("close");
      this.newPassword = "";
      this.confirmPassword = "";
      this.password = "";
      this.bgColor = "bg-carrotOrange";
      this.bgButton = "bg-fadeFlame";
      this.savePasswd = false;
      this.width = "0%";
      this.has_number = false;
      this.has_lowercase = false;
      this.has_uppercase = false;
      this.has_special = false;
      this.has_complete = false;
      this.response = "";
      this.responseBg = "";
      this.success = false;
      this.loading = false;
      this.disableBtn = false;
      this.visibility.first = true;
      this.visibility.second = false;
      this.visibility.third = false;
      this.error.first = false;
      this.error.second = false;
      this.error.third = false;
      this.passwordLength = [];
      this.decryptFirstPassword = false;
      this.decryptSecondPassword = false;
      this.decryptThirdPassword = false;
    }
  }
};
</script>

<style>
.border__dashed {
  height: 0px;
  margin: 18px 0px;
  border: 1px dashed #878e99;
}
.bg-fadeFlame {
  background-color: rgba(241, 90, 41, 0.5);
}
</style>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
