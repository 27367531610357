<template>
  <div>
    <div class=" w-full">
      <p class=" font-semibold mb-4 inline-block">
        Annual Gross Pay
      </p>
    </div>
    <div class="col-span-12">
      <div class="text-base flex text-jet">
        <p>Payroll Status:</p>
        <p
          class="ml-4 "
          :class="
            payrollStatus === 'active' ? ' text-mediumSeaGreen' : 'text-flame'
          "
        >
          {{ payrollStatus ? toTitleCase(payrollStatus) : "Inactive" }}
        </p>
      </div>
    </div>
    <div class="flex w-full  my-3 text-darkPurple" style="margin-top:1.125rem">
      <div class="ml-3 w-64">
        <div class="text-sm w-full h-10 text-jet">
          Annual Gross Pay
        </div>
        <div class="disabled">
          {{ annualGross }}
        </div>
      </div>
    </div>
    <!-- <table class=" w-full mt-4">
      <thead>
        <tr>
          <th>#</th>
          <th
            class=" uppercase"
            v-for="(header, index) in headers"
            :key="index"
          >
            <p>{{ header.title }}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(data, i) in grossEarningsData"
          :key="i"
          class="tableBody text-sm"
          style="height: 48px; "
        >
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ data.payItem.name }}
          </td>
          <td>
            {{ Capitalize(data.payType) }}
          </td>
          <td>
            {{ data.rateType.label }}
          </td>
          <td>
            {{ data.rateValue }}
          </td>
          <td>
            {{ data.actualAmount ? convertToCurrency(data.actualAmount) : "-" }}
          </td>
        </tr>
        <tr class="h-7" />
        <tr
          style="height: 52px; border-spacing: 15px 0;"
          class="mt-4 bg-ghostWhite"
        >
          <td class="" />
          <td class="font-bold text-lg text-darkPurple ">
            Total
          </td>
          <td />
          <td />
          <td class="font-bold text-blueCrayola text-lg">
            {{ "%" }}
          </td>
          <td class="font-bold">
            {{ convertToCurrency(totalGrossEarning) }}
          </td>
        </tr>
      </tbody>
    </table> -->
    <accordion-pay-info-table
      :gross-earnings-data="grossEarningsData"
      :total-amount="totalGrossAmount"
      :total-percent="totalPercent"
      :variable-table="variableData"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
    AccordionPayInfoTable: () =>
      import(
        "@/modules/Admin/EmployeeModule/EmployeeDirectory/EditEmployee/payInformation/annualGross/accordionPayInfoTable"
      )
  },
  props: {
    totalGross: {
      type: String,
      default: ""
    },
    totalGrossEarning: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      payrollStatus: null,
      error: false,
      setSalary: "",
      annualGross: "",
      variableData: {},
      totalGrossAmount: 0,
      totalPercent: 0,
      grossEarningsData: [],
      headers: [
        { title: "pay item" },
        { title: "pay type" },
        { title: "rate type" },
        { title: "rate (%)" },
        { title: "amount(₦)" }
      ]
    };
  },
  computed: {
    ...mapState({
      employeeDetails: state => state.employee.allEmployeeDetails
    }),
    totalRate() {
      const result = 7;
      return result;
    },
    totalAmount() {
      const result = "---";
      return result;
    }
  },
  watch: {
    annualGross(value) {
      let newValue = Number(value.split(",").join(""));
      const salary = this.employeeDetails.userDesignation;
      const lowerBand = Number(salary.lowerAnnualSalaryBand);
      const upperBand = Number(salary.upperAnnualSalaryBand);

      if (newValue > lowerBand && newValue < upperBand) {
        this.error = false;
      } else {
        this.error = true;
      }

      newValue = newValue.toString();
      return newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  methods: {
    editAnnualGross() {
      this.annualGrossPay.isDisabled = false;
      this.annualGrossPay.isEdit = false;
    },
    async getEmployeeSalary() {
      const { data } = await this.$_getEmployeeSalaryBreakDown({
        id: this.$AuthUser.id
      });
      this.grossEarningsData = data.salaryBreakdown;
      this.totalGrossAmount = data.totalGrossAmount;
      this.totalPercent = data.totalGrossPercent;
      this.variableData = data.variableSalaryBreakdown;
    }
  },
  async mounted() {
    const { grossPay, payrollStatus } = await this.employeeDetails;
    this.annualGross = grossPay.toString();
    this.annualGross = this.annualGross
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.payrollStatus = payrollStatus;
    this.getEmployeeSalary();
  }
};
</script>

<style lang="scss" scoped>
.payInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  padding: 10px 15px;
  max-height: 40px;
  width: 251px;
  &_fakeInput {
    background: #f7f7ff;
    border-color: #878e99;
  }
  &_disabled {
    background: #eeeeee;
    border: none;
    color: #878e99;
  }
  &_unallocated {
    padding: 12px 16px;
    background: #fff5f5;
    border-color: #ea3c53;
    max-height: 65px;
  }
}
</style>
