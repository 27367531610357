<template>
  <div class=" w-2/12 mr-6">
    <div class="mb-8">
      <image-upload
        image-style="height: 100px; width: 100px"
        icon-style="top:81%; left: 70%;bottom: 3.33%;border-radius: 100%"
        @file="handleUpload($event)"
        :url="url"
        :photo="employeeBasic.photo"
        :name="`${employeeBasic.fname} ${employeeBasic.lname}`"
      />
    </div>
    <div>
      <span
        class=" text-base text-darkPurple font-semibold block py-2 cursor-pointer"
        v-for="(tab, i) in tabs"
        :key="i"
        :class="[
          currentTab === tabs[i] ? 'activeTab tw-text-primary' : 'inactiveTab'
        ]"
        @click="$emit('currentTab', tab)"
      >
        {{ tab }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { clone } from "lodash";
import imageUpload from "@/components/imageUpload";

export default {
  components: { imageUpload },
  name: "SideTabs",
  // eslint-disable-next-line vue/require-prop-types
  props: ["currentTab", "tabs", "employeeDetails"],
  data() {
    return {
      photo: null,
      url: process.env.VUE_APP_IMAGE_UPLOAD
    };
  },
  methods: {
    handleUpload(file) {
      const employeeInfo = clone(this.employeeBasic);
      employeeInfo.containsBio = true;
      employeeInfo.photo = file;
      this.$_editEmployeeByType({
        id: this.$AuthUser.id,
        type: "profile",
        payload: employeeInfo
      }).then(() => {
        this.photo = file;
        this.$toasted.success("Profile picture changed successfully", {
          duration: "3000"
        });
      });
    }
  },
  computed: {
    ...mapState({
      employeeBasic: state => state.employee.allEmployeeDetails
    })
  },
  mounted() {
    this.$store.dispatch("employee/getOneEmployeeAll", this.$AuthUser.id);
  }
};
</script>

<style scoped>
.activeTab {
  border-right: 5px solid #ee5d4f;
}

.inactiveTab {
  color: #b8b7b8;
}
</style>
