<template>
  <div class="w-10/12">
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class="mt-6">
      <div class="w-full">
        <p class="font-semibold mb-7 inline-block">Bio Data</p>
        <span
          v-if="!hasSubscriptionExpired() && canUpdateProfileInfo"
          class="float-right cursor-pointer text-flame text-base font-semibold"
        >
          <span @click="edit = !edit" v-if="!edit">
            <icon icon-name="icon-edit" size="xs" class=" inline-block" />
            <span class="pl-2">Edit</span>
          </span>
          <span @click="handleSave" v-else>
            <icon icon-name="save" size="xs" class="inline-block" />
            <span class="pl-2">Save</span>
          </span>
        </span>
      </div>
      <div
        class="grid grid-cols-12 gap-3 text-darkPurple"
        style="margin-top: 1.125rem"
      >
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            label="First Name"
            v-model="employeeBasic.fname"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            label="Middle Name"
            v-model="employeeBasic.mname"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full h-10"
            label="Last Name"
            v-model="employeeBasic.lname"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-select
            placeholder="--Enter--"
            variant="w-full h-10"
            :options="gender"
            label="Gender"
            v-model="employeeBasic.gender"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <label class="date-label">Date of Birth</label>
          <div :class="!edit ? 'date-flex_disabled' : 'date-flex'">
            <datepicker
              placeholder="--Enter--"
              :input-class="
                !edit ? 'date-input date-input_disabled' : 'date-input'
              "
              style="width: 100%; outline: none"
              v-model="employeeBasic.dob"
              :disabled="!edit"
            />
          </div>
        </div>
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full h-10"
            label="Phone Number"
            v-model="employeeBasic.phoneNo"
            :disabled="!edit"
          />
        </div>

        <div class="col-span-3">
          <div class="text-sm mb-1 text-jet">Work Email</div>
          <div
            class="wrapper"
            v-tooltip.bottom-start="employeeBasic.email"
            :class="!edit ? 'disabled disabled_hideOutline' : 'disabled'"
          >
            {{ employeeBasic.email }}
          </div>
        </div>
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            label="Personal Email"
            v-model="employeeBasic.alternateEmail"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-select
            placeholder="--Enter--"
            variant="w-full h-10"
            label="State of Origin"
            :options="$store.state.states"
            v-model="employeeBasic.stateOfOrigin"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            label="Local Government Area"
            v-model="employeeBasic.localGovernmentArea"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-select
            placeholder="--Enter--"
            variant="w-full h-10"
            label="ID Type"
            :options="idType"
            v-model="employeeBasic.idType"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            label="ID Number"
            v-model="employeeBasic.idNumber"
            :disabled="!edit"
          />
        </div>
      </div>
      <divider class="my-8" />
      <p class="font-semibold mb-7">Address</p>
      <div
        class="grid grid-cols-12 gap-6 text-darkPurple"
        style="margin-top: 1.125rem"
      >
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            label="Address"
            v-model="employeeBasic.address"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-select
            placeholder="--Enter--"
            variant="w-full"
            :options="countrySelect"
            @change="changeState"
            label="Country"
            v-model="employeeBasic.country"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full h-10"
            label="City"
            v-model="employeeBasic.city"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-select
            placeholder="--Enter--"
            v-if="employeeBasic.country === 'NGA'"
            variant="w-full h-10"
            label="State"
            :options="$store.state.states"
            v-model="employeeBasic.state"
            :disabled="!edit"
          />
          <c-text
            placeholder="--Enter--"
            v-else
            variant="w-full h-10"
            label="State"
            v-model="employeeBasic.state"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full h-10"
            label="Zip Code"
            v-model="employeeBasic.zip"
            :disabled="!edit"
          />
        </div>
      </div>
      <divider class="my-8" />
      <p class="font-semibold mb-7">Next of Kin</p>
      <div
        class="grid grid-cols-12 gap-6 text-darkPurple"
        style="margin-top: 1.125rem"
      >
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            label="First Name"
            v-model="employeeBasic.nokFname"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            label="Last Name"
            v-model="employeeBasic.nokLname"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full h-10"
            label="Phone Number"
            v-model="employeeBasic.nokPhone"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full h-10"
            label="Email"
            v-model="employeeBasic.nokEmail"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-select
            placeholder="--Enter--"
            variant="w-full h-10"
            :options="$store.state.nok"
            label="Relationship"
            v-model="employeeBasic.nokRelationship"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            variant="w-full h-10"
            label="Address"
            v-model="employeeBasic.nokAddress"
            :disabled="!edit"
          />
        </div>
      </div>
      <divider class="my-8" />
      <div>
        <div class="flex mt-4" v-if="edit">
          <Button @click="edit = !edit" class="buttons"> Cancel </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import { mapState } from "vuex";
import Datepicker from "vuejs-datepicker";
import gender from "@/helpers/gender";
import Divider from "@/components/divider";
import Button from "@/components/Button";

export default {
  name: "PersonalInfo",
  components: {
    CText,
    CSelect,
    Datepicker,
    Button,
    Divider,
  },

  data() {
    return {
      idType: [
        {
          id: "NationalId",
          name: "National ID",
        },
        {
          id: "InternationalPassportID",
          name: "International Passport ID",
        },
        {
          id: "DriversLicenseID",
          name: "Drivers License ID",
        },
      ],
      edit: false,
      gender,
      photo: "",
      email: "",
      error: [],
      countrySelect: [],
      allowNextPage: false,
      showStates: true,
    };
  },
  computed: {
    ...mapState({
      employeeBasic: (state) => state.employee.allEmployeeDetails,
      canUpdateProfileInfo: (state) =>
        state.employee.essPermissions.canUpdateProfileInfo,
      loading: (state) => state.employee.loading,
    }),
    hasSubscriptionExpired() {
      return this.$store.getters.hasSubscriptionExpired;
    },
  },
  methods: {
    handleSave() {
      this.employeeBasic.containsBio = true;
      this.edit = false;
      this.$_editEmployeeByType({
        id: this.$AuthUser.id,
        type: "profile",
        payload: this.employeeBasic,
      });
    },
    changeState(value) {
      if (value === "NGA") {
        this.showStates = true;
      } else this.showStates = false;
    },
  },
  async mounted() {
    this.countrySelect = this.$store.state.countries.map((element) => ({
      id: element.Alpha3Code,
      name: element.Name,
    }));
  },
};
</script>

<style>
.wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
