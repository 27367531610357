<template>
  <div class="w-10/12">
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class="mt-6">
      <div class="w-full">
        <p class="font-semibold mb-7 inline-block">Education & Certification</p>
        <span
          v-if="!cannotEdit"
          class="float-right cursor-pointer text-flame text-base font-semibold"
        >
          <span @click="handleClick" v-if="!edit">
            <icon icon-name="icon-edit" size="xs" class="inline-block" />
            <span class="pl-2">Edit</span>
          </span>
          <span @click="handleSave" v-else>
            <icon icon-name="save" size="xs" class="inline-block" />
            <span class="pl-2">Save</span>
          </span>
        </span>
      </div>

      <div class="flex flex-col gap-2 mt-1 border p-4 rounded-lg">
        <ValidationObserver v-slot="{ handleSubmit }">
          <div class="flex mt-2">
            <span class="text-sm font-semibold flex flex-grow"
              >Educational Credentials</span
            >
            <span
              class="flex gap-1 cursor-pointer"
              @click="handleSubmit(addCredentials)"
            >
              <icon
                icon-name="icon-plus"
                class-name="text-carrotOrange"
                size="xms"
              />
              <span class="text-sm text-flame font-semibold">Add another</span>
            </span>
          </div>
          <divider />
          <div
            class="grid grid-cols-12 gap-6 text-darkPurple"
            style="margin-top: 1.125rem"
            v-for="(data, index) in educationalInfo"
            :key="index"
          >
            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Qualification"
                :rules="['required']"
                :disabled="!edit"
                v-model="data.qualification"
              />
            </div>
            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Institution"
                :rules="['required']"
                :disabled="!edit"
                v-model="data.institution"
              />
            </div>
            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                variant="w-full h-10"
                label="Year Of Award"
                :rules="['required']"
                :disabled="!edit"
                v-model="data.yearOfAward"
              />
            </div>
          </div>
        </ValidationObserver>
      </div>

      <divider class="my-5" />

      <div class="flex flex-col gap-2 border p-4 rounded-lg">
        <ValidationObserver v-slot="{ handleSubmit }">
          <div class="flex mt-2">
            <span class="text-sm font-semibold flex flex-grow"
              >Professional Certifications</span
            >
            <span
              class="flex gap-1 cursor-pointer"
              @click="handleSubmit(addCertifications)"
            >
              <icon
                icon-name="icon-plus"
                class-name="text-carrotOrange"
                size="xms"
              />
              <span class="text-sm text-flame font-semibold">Add another</span>
            </span>
          </div>
          <divider />
          <div
            class="grid grid-cols-12 gap-6 text-darkPurple"
            style="margin-top: 1.125rem"
            v-for="(data, index) in certificationInfo"
            :key="index"
          >
            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Qualification"
                :rules="['required']"
                :disabled="!edit"
                v-model="data.qualification"
              />
            </div>
            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Institution"
                :rules="['required']"
                :disabled="!edit"
                v-model="data.institution"
              />
            </div>
            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                variant="w-full h-10"
                label="Year Of Award"
                :disabled="!edit"
                :rules="['required']"
                v-model="data.yearOfAward"
              />
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Divider from "@/components/divider";
import Button from "@/components/Button";

export default {
  name: "EmployeeCertificate",
  components: {
    ValidationObserver,
    CText,
    Divider,
    Button,
  },
  props: {
    cannotEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  data() {
    return {
      loading: true,
      edit: false,
      educationalInfo: [],
      certificationInfo: [],
    };
  },

  methods: {
    async handleClick() {
      this.edit = !this.edit;
    },

    handleSave() {
      const certificationInfoCheck = this.checkIsEmpty(this.certificationInfo);
      const educatationalInfoCheck = this.checkIsEmpty(this.educationalInfo);

      const payload = {
        educatationalInfo: educatationalInfoCheck,
        certificationInfo: certificationInfoCheck,
      };

      this.$_createEmployeeCertifications(payload, this.$AuthUser.id).then(
        (response) => {
          this.$toasted.success(response.data.message, { duration: 3000 });
        }
      ).catch((error) => {
        this.$toasted.error(error.message, { duration: 3000 });
      });
    },

    checkIsEmpty(arrData) {
      const data = arrData.every(obj => {
        return Object.keys(obj).every(key => obj[key] === "");
      });

      return data ? null : arrData
    },

    addCertifications() {
      this.certificationInfo.push({
        institution: "",
        qualification: "",
        yearOfAward: "",
      });
    },

    addCredentials() {
      this.educationalInfo.push({
        institution: "",
        qualification: "",
        yearOfAward: "",
      });
    },
  },

  mounted() {
    this.$_employeeCertifications(this.$AuthUser.id).then((response) => {
      if (response.data.certificationInfo.length === 0) {
        this.certificationInfo.push({
          institution: "",
          qualification: "",
          yearOfAward: "",
        });
      } else {
        this.certificationInfo = response.data.certificationInfo;
      }

      if (response.data.educationalInfo.length === 0) {
        this.educationalInfo.push({
          institution: "",
          qualification: "",
          yearOfAward: "",
        });
      } else {
        this.educationalInfo = response.data.educationalInfo;
      }

      this.loading = false;
    });
  },
};
</script>
