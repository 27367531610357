<template>
  <div>
    <div class=" w-full">
      <p class=" font-semibold mb-4 inline-block">
        Other Benefits
      </p>
    </div>
    <table aria-label="benefit" class=" w-full">
      <thead>
        <tr>
          <th id="number">#</th>
          <th
            class=" uppercase"
            id="benefitHeader"
            v-for="(header, index) in headers"
            :key="index"
          >
            <p>{{ header.title }}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(data, i) in otherBenefitData"
          :key="i"
          class="tableBody text-sm"
          style="height: 48px; "
        >
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ data.payItem.name }}
          </td>
          <td>
            {{ Capitalize(data.payType) }}
          </td>
          <td>
            {{ data.rateType.label }}
          </td>
          <td>
            <span>
              {{
                data.rateType.slug === "fixedAmount" ||
                data.rateType.placeholder === "Enter Amount" ||
                data.rateType.slug === "dailyRate"
                  ? "₦" + data.rateValue
                  : data.rateType.dataType === "percent"
                  ? data.rateValue + "%"
                  : data.rateType.placeholder === "Enter Multiple (eg x 1.5)"
                  ? data.rateValue + "X"
                  : ""
              }}
            </span>
          </td>
          <td>
            {{ convertToCurrency(data.actualAmount) }}
          </td>
        </tr>
        <tr class="h-7" />
        <tr
          style="height: 52px; border-spacing: 15px 0;"
          class="mt-4 bg-ghostWhite"
        >
          <td class="" />
          <td class="font-bold">
            Hourly Work Rate:
          </td>
          <td class="font-bold">
            {{ convertToCurrency(employeeDetails.hourlyRate) }}
          </td>
          <td class="font-bold">
            Daily Work Rate:
          </td>
          <td class="font-bold">
            {{ convertToCurrency(employeeDetails.dailyRate) }}
          </td>
          <td class="font-bold" />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    totalGross: {
      type: String,
      default: ""
    },
    otherBenefitData: {
      type: Array,
      default() {
        return [];
      }
    },
    totalGrossEarning: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      edit: false,
      headers: [
        { title: "pay item" },
        { title: "pay type" },
        { title: "rate type" },
        { title: "rate" },
        { title: "rate amount(₦)" }
      ]
    };
  },
  computed: {
    ...mapState({
      employeeDetails: state => state.employee.allEmployeeDetails
    }),
    totalAmount() {
      let result = 0;
      let number = 0;
      this.otherBenefitData.forEach(element => {
        number = parseFloat(element.amount.split(",").join(""));
        result += number;
      });
      result = result.toLocaleString();
      return result;
    }
  },
  methods: {}
};
</script>

<style></style>
