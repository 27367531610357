<template>
  <div class="w-10/12">
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class=" mt-6">
      <div class=" w-full">
        <p class=" font-bold mb-7 text-lg inline-block">
          Employee Role
        </p>
      </div>

      <div v-if="roleId">
        <div
          class="grid grid-cols-12 gap-6 text-darkPurple"
          style="margin-top:1.125rem"
        >
          <div class="col-span-5">
            <accordion
              @open="getRolesPrivileges(role.id)"
              v-for="role in roleSelect"
              show-border
              :key="role.id"
            >
              <template v-slot:title>
                <span class="text-sm font-extrabold ml-2 uppercase">{{
                  role.name
                }}</span>
              </template>
              <div style="height:100%" v-if="loadingPrivileges">
                <loader size="xxs" :loader-image="false" />
              </div>
              <scroll-container height="200px">
                <table aria-label="this shows module privileges" class="w-full">
                  <tbody>
                    <tr v-for="(item, i) in privileges" :key="i">
                      <td>
                        {{ item.name }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </scroll-container>
            </accordion>
          </div>
        </div>
      </div>
      <div class="font-extrabold" v-else>
        You are not assigned any role
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/Accordion";
import ScrollContainer from "@/components/ScrollContainer";
import Loader from "@/components/Loader";

export default {
  name: "EmployeeRole",
  components: {
    Loader,
    Accordion,
    ScrollContainer
  },

  data() {
    return {
      edit: false,
      loading: true,
      roleId: null,
      roleSelect: [],
      subModules: [],
      selectedItems: [],
      privileges: [],
      modules: [],
      loadingPrivileges: true,
      success: false,
      response: "",
      responseBg: ""
    };
  },
  methods: {
    getUserRole() {
      const userId = this.$AuthUser.id;
      this.$_getUser(userId).then(result => {
        this.roleId =
          Object.keys(result.data.user.roles).length > 0
            ? result.data.user.roles.humanar.roleId
            : null;
        this.getHumanarRoles(this.roleId);
      });
    },
    getHumanarRoles(roleId) {
      this.$_NewGetOrgRoles().then(result => {
        const orgRoles = result.data.roles;
        this.roleSelect = orgRoles.filter(v => v.id === roleId);
      });
      this.loading = false;
    },
    async getRolesPrivileges(id) {
      this.privileges = [];
      this.loadingPrivileges = true;
      const response = await this.$_NewGetUpdateRoles(id);
      this.modules = response.data.modules.filter(v => v.hasPrivilege);
      this.modules.forEach(v => {
        v.submodules.reduce(this.reducer, []);
      });
      this.loadingPrivileges = false;
    },
    reducer(acc, current) {
      if (current.hasPrivilege) {
        const hasPrivileges = current.privileges.filter(
          item => item.hasPrivilege
        );
        if (hasPrivileges.length) {
          hasPrivileges.forEach(privilege => {
            this.privileges.push(privilege);
          });
        }
      }
    }
  },
  mounted() {
    this.getUserRole();
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
